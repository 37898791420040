<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <v-col sm="12" cols="12" class="py-0">
                  <h2 class="mb-0">
                    {{ $t("user_access_role") }}
                  </h2>
                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.show"
                    right
                  >
                    {{ snackbar.message }}
                  </v-snackbar>
                </v-col>
              </v-row>
              <v-row>
                <v-divider class="ma-2 mt-8 mb-5" color="#e9e9e9"></v-divider>
                <v-col sm="12" cols="12" class="py-0">
                  <template>
                    <v-card>
                      <v-card-title>
                        <v-spacer></v-spacer>
                        <v-col sm="4" cols="12">
                          <v-text-field
                            outlined
                            append-icon="search"
                            label="Search"
                            v-model="search"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-col>
                      </v-card-title>
                      <v-data-table
                        item-key="id"
                        class="elevation-1"
                        :headers="headers"
                        :items="listRoles"
                        :search="search"
                        :items-per-page="25"
                        :footer-props="{
                          'items-per-page-options': [10, 25, 50, -1],
                        }"
                        hide-default-header
                      >
                        <template v-slot:header="{}">
                          <thead>
                            <tr>
                              <th>{{ $t("no") }}</th>
                              <th>{{ $t("name_en") }}</th>
                              <th>{{ $t("name_kh") }}</th>
                              <th>{{ $t("value") }}</th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:body="{ items }">
                          <tbody style="white-space: nowrap">
                            <tr
                              v-for="(role, index) in items"
                              v-bind:key="index"
                            >
                              <td>{{ 1 + index++ }}</td>
                              <td>{{ role.name_en }}</td>
                              <td>{{ role.name_kh }}</td>
                              <td>
                                <v-chip :color="getColor(role.value)" dark>
                                  {{ role.value }}
                                </v-chip>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </v-card>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>
<script>
// const axios = require("axios");
// const apiUrl = require("../../apiUrl");
import store from "@/store";
import {
  createAccessRole,
  getAccessRole,
  updateAccessRole,
  deleteAccessRole,
} from "@schoolbase/web-client-lib";
// import { edit_user } from '../../apiUrl';
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: ["fullPage", "alertMessage", "color", "alertText"],
  data() {
    return {
      search: "",
      headers: [
        { text: "No", value: "no" },
        { text: "English Name", value: "name_en" },
        { text: "Khmer Name", value: "name_kh" },
        { text: "Value", value: "value" },
      ],
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      listRoles: [],
      dialog: false,
      isLoading: false,
      myLoading: false,
      token: store.getters.getToken,
      name_en: "",
      name_kh: "",
      value: "",
      roleId: "",
    };
  },
  methods: {
    getColor(value) {
      if (value === "super_admin") {
        return "green";
      } else if (value === "lecturer") {
        return "red";
      } else if (value === "student") {
        return "darkgray";
      } else if (value === "top_manager") {
        return "orange";
      } else if (value === "major_admin_cs") {
        return "olive";
      } else if (value === "major_admin_ecom") {
        return "pink";
      } else if (value === "major_admin_tn") {
        return "lime";
      } else if (value === "major_admin_foundation") {
        return "cyan";
      } else {
        return "blue";
      }
    },

    onEditItem(item) {
      this.name_en = item.name_en;
      this.name_kh = item.name_kh;
      this.value = item.value;
      this.roleId = item.id;
      this.dialog = true;
    },
    clear() {
      (this.name_en = ""),
        (this.name_kh = ""),
        (this.value = ""),
        (this.roleId = ""),
        (this.id = "");
    },
    //load User Function

    //Create User Function
    async onSaveClose() {
      let data = {
        name_kh: this.name_kh,
        name_en: this.name_en,
        value: this.value,
      };
      if (this.roleId !== "") {
        try {
          await updateAccessRole(this.roleId, data);
          this.loadRole();
          this.clear();
        } catch (e) {
          this.snackbar = {
            message: e,
            color: "error",
            show: true,
          };
        }
      } else {
        try {
          await createAccessRole(data);
          this.loadRole();
          this.clear();
        } catch (e) {
          this.snackbar = {
            message: e,
            color: "error",
            show: true,
          };
        }
      }

      this.dialog = false;
    },
    async loadRole() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const APIResponse = await getAccessRole(this.token);
        this.listRoles = APIResponse.payload;
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }

        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }

        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
    async deleterole(role) {
      const index = this.listRoles.indexOf(role);
      this.deletItems = role;
      const btnCancel = confirm("Are you sure you want to delete this item?");
      if (btnCancel === true) {
        this.listRoles.splice(index, 1);
        await this.deleteRole();
      }
    },
    async deleteRole() {
      // new Promise((resolve) => {
      //   setTimeout(() => {
      //     resolve("resolved");
      //     axios
      //       .delete(apiUrl.delete_role + this.deletItems.id, {})
      //       .then((response) => {
      //         window.console.log(response);
      //       })
      //       .catch((e) => {
      //         this.errors.push(e);
      //       });
      //   }, 200);
      // });

      await deleteAccessRole(this.deletItems.id);
    },
  },

  async mounted() {
    this.loadRole();
  },
  components: {
    Loading,
  },
};
</script>
<style scoped>
.v-data-table thead th {
  font-size: 16px !important;
  text-align: center !important;
}
.v-data-table tbody {
  text-align: center !important;
}
.btn_edit {
  background-color: #4e6470 !important;
  color: #fff !important;
  height: 35px;
}

.v-card__actions .v-btn.v-btn {
  padding: 0 16px;
}

.v-menu__content {
  top: 141px !important;
  left: 1098px !important;
}

.v-menu__content .v-list .v-list-item {
  min-height: 35px !important;
}

.tab_wrapper {
  position: relative;
  display: inherit;
}

.v-tab {
  min-width: 30px;
  font-size: 15px;
  text-transform: capitalize;
}

.v-icon--left {
  margin-right: 0px;
}

.dropdown_transactions.v-btn {
  background-color: #2ca01c !important;
  height: 40px !important;
  width: auto !important;
  border-radius: 0 !important;
  padding: 0px 10px 0px 10px !important;
  color: #fff !important;
  right: 12px;
  position: absolute;
  top: 7px;
}

@media (max-width: 576px) {
}
</style>
